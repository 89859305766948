<template>
  <div>
    <div class="ll-box">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Amazon Order') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            {{ $t('Pre Alert') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- Add Btn -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="info"
          :to="{ name: 'add-mawb' }"
          v-permission="[138]"
        >
          <span class="text-nowrap">{{ $t('Add a new MAWB') }}</span>
        </b-button>
      </div>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-input-group>
              <b-input-group-prepend
                is-text
                v-b-toggle.filterS
                class="ll-outline"
                :style="filterBg">
                <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-form-textarea
                v-model="mawbNo"
                class="searchInput"
                :placeholder="$t('Please Enter MAWB No')"
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                @click="searchById">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title=""
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item :title="$t('Airline')">
            <v-select
              id="airLine"
              v-model="filterCondition.airline"
              :options="airLineList"
              label="name"
              @input="name => update(name)"
              placeholder="Please Enter Airline"
            >
              <template #option="{ iata, name }">
                <span class="ml-50 align-middle"> {{ name }}({{ iata}})</span>
              </template>
            </v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Creation Date from-to')">
            <b-row>
              <b-col>
                <b-form-group
                  class="g-label ll-bform"
                  label=""
                  label-for="formTime"
                >
                  <flat-pickr
                    id="formTime"
                    v-model="filterCondition.createTimeFrom"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  class="g-label ll-bform"
                  label=""
                  label-for="toTime"
                >
                  <flat-pickr
                    id="toTime"
                    v-model="filterCondition.createTimeTo"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d H:i:ss'}"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item :title="$t('EtaDate from-to')">
            <b-row>
              <b-col>
                <b-form-group
                  class="g-label ll-bform"
                  label=""
                  label-for="formTime1"
                >
                  <flat-pickr
                    id="formTime1"
                    v-model="filterCondition.etaTimeFrom"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  class="g-label ll-bform"
                  label=""
                  label-for="toTime2"
                >
                  <flat-pickr
                    id="toTime2"
                    v-model="filterCondition.etaTimeTo"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d H:i:ss'}"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            v-b-toggle.filterS
            @click="filterSearch">
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <!-- table -->
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      responsive
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-permission="[137]"
      :sticky-header="true"
      @row-clicked="onDetail"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <feather-icon
          :id="`${data.item.id}`"
          icon="EyeIcon"
          size="16"
          class="mx-1"
          @click="$router.push({ name: 'mawb-details', params: { id: data.item.mawb }})"
          v-permission="[139]"
        >
        <b-tooltip
          title="View Details"
          :target="`${data.item.id}`"
        />
        </feather-icon>
      </template>
    </b-table>
    <!-- show pages -->
    <div class="d-flex justify-content-between flex-wrap ll-p">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BPagination, BFormSelect, BButton, BTooltip, VBToggle,
  BCard, BCardBody, BTable, BRow, BCol, BFormTextarea, BFormGroup, BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem, BOverlay, BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BPagination,
    BFormSelect,
    BFormTextarea,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    flatPickr,
    BFormGroup,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'mawb', label: this.$t('MAWB No'), sortable: true },
        { key: 'createdAt', label: this.$t('Creation Date'), sortable: true },
        { key: 'customer', label: this.$t('Customer'), sortable: true },
        { key: 'airLine', label: this.$t('Airline'), sortable: true },
        { key: 'totalQuantity', label: this.$t('TotalQty'), sortable: true },
        { key: 'etaTime', label: 'ETA', sortable: true },
        { key: 'ataTime', label: 'ATA', sortable: true },
        { key: 'cusStartTime', label: this.$t('CUS start'), sortable: true },
        { key: 'cusFinishTime', label: this.$t('CUS finish'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      mawbNo: '',
      condition: {
        page: '1',
        size: '50',
        orderBy: 'id',
        orderMole: 1,
        mawbs: [],
      },
      creationDate: '',
      etaDate: '',
      airlineInfo: '',
      airLineList: [],
      airlineRaw: {
        page: '1',
        size: 200,
        orderBy: 'id',
        sequence: 'asc',
      },
      filterCondition: {
        mawbs: [],
        airLine: '',
        createTimeFrom: '',
        createTimeTo: '',
        etaTimeFrom: '',
        etaTimeTo: '',
        page: '1',
        size: '15',
        orderBy: 'id',
        sequence: 'asc',
      },
      airLine: '',
      filterBg: {
        '--background': '#fff'
      },
      isBusy: true,
    }
  },
  computed: {
  },
  watch: {
    airLine(val) {
      this.setBg(val)
    },
    'filterCondition.createTimeFrom'(val) {
      // this.setBg(val)
    },
    'filterCondition.createTimeTo'(val) {
      // this.setBg(val)
    },
    'filterCondition.etaTimeFrom'(val) {
      // this.setBg(val)
    },
    'filterCondition.etaTimeTo'(val) {
      // this.setBg(val)
    },
  },
  created() {
  },
  mounted() {
    this.getList()
    this.getAirLines()
  },
  methods: {
    // filter图标背景颜色设置
    setBg(val) {
      if (val !== null) {
        this.filterBg= {
          '--background': '#ff9f43'
        }
      } else {
        this.filterBg= {
          '--background': '#fff'
        }
      }
    },
    // 首次查询
    getList() {
      this.$http.post('/prealert/search', this.condition).then(res => {
        // console.log(res.data.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.rows = info.content
          this.page = info.page
          this.size = info.size
          this.totalSize = info.totalSize
          this.totalPage = info.totalPage
          this.isBusy = false
        }
      }).catch(error => console.log(error))
    },
    onRowClick() {
    },
    onSearch() {
    },
    onReset() {
      this.mawbNo = ''
      this.creationDate = ''
      this.etaDate = ''
      this.airlineInfo = ''
    },
    // 根据MawbNo查询
    searchById() {
      if (this.mawbNo.trim() !== '') {
        this.condition.mawbs = this.mawbNo.split(/[(\r\n)\r\n]+/)
        this.getList()
      } else {
        this.condition.mawbs = []
        this.getList()
      }
    },
    handleChangePage(page) {
    //   console.log('page', page)
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
    //   console.log('active', active)
      this.condition.size = active
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      // console.log(orderBy, orderMole)
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    // 获取航班列表
    getAirLines() {
      this.$http.post('/airline/search', this.airlineRaw).then(res => {
        this.airLineList = res.data.data.content
      })
    },
    update(name) {
      this.filterCondition.airline = `${name.name}(${name.iata})`
      this.airLine = name.iata
    },
    // 条件过滤搜索
    filterSearch() {
      this.filterCondition.airLine = this.airLine
      this.$http.post('/prealert/search', this.filterCondition).then(res => {
        // console.log(res.data.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          this.rows = res.data.data.content
        }
      }).catch(error => console.log(error))
    },
    onClear() {
      this.filterCondition.createTimeFrom = null
      this.filterCondition.createTimeTo = null
      this.filterCondition.etaTimeFrom = null
      this.filterCondition.etaTimeTo = null
      this.filterCondition.airLine = null
      this.airLine = null
    },
    onDetail(item){
      this.$router.push({ name: 'mawb-details', params: { id: item.mawb }})
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: fixed;
  bottom: 40px;
  right: 10px;
} */
.btn-mrg{
  margin-left: 10px;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-outline> div{
  background: var(--background);
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
</style>
