<template>
  <b-row>
    <b-col cols="12">
      <pre-alert-info></pre-alert-info>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import PreAlertInfo from './components/preAlertInfo.vue'

export default {
  components: {
    BRow,
    BCol,
    PreAlertInfo,
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
